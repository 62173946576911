import React from "react"
import { slide as Menu } from 'react-burger-menu'
import { Link } from 'gatsby'
import '../styles/components/burger-menu.scss';

class BurgerMenu extends React.Component {

  showSettings (event) {
    event.preventDefault();
  }
  
  render () {
   
    return (
      <Menu right pageWrapId={ "page-wrap" } outerContainerId={ "outer-container" } width={ '30%' } >
        <Link to="/">Home</Link>
        <Link to="/our-work">Our Work</Link>
        {/*<Link to="/solutions/">Digital Solutions</Link>
        <Link to="/insights/">Insights</Link>
        <Link to="/contact-us/">Contact Us</Link>*/}
      </Menu>

    );
  }
}
export default BurgerMenu;