
import React from "react"
import '../styles/layout/header.scss';
import BurgerMenu from "./buger-menu"; 

const Header = (props) =>{
    return (
    <header className= {props.nav}>  
    <BurgerMenu />
   </header>
       
        

    )
}
export default Header