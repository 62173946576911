import React from "react"
import {Container, Grid, Icon} from 'semantic-ui-react';
import { Link } from 'gatsby'
import * as footer from '../styles/footer.module.scss';

const Footer = () =>{
        const d = new Date();
        let year = d.getFullYear();
                
        return (
     
            
            <Container className={footer.footer} fluid>
                <Grid columns={3} stackable padded>
                    <Grid.Row>
                        <Grid.Column className={footer.left}>
                            <p>
                                Tel: (767) 295-7950 <br />
                                Roseau, Dominica - B.W.I<br />
                            </p>
                        </Grid.Column>
            
                        <Grid.Column className={footer.middle} >
                            <p>
                                
                                <Link to="https://www.linkedin.com/company/eliteprocreative/" target="_blank"><Icon name='linkedin' size='big' /></Link>
                            </p>
                        </Grid.Column>

                        <Grid.Column className={footer.right} >
                            <p>
                            Elitepro Creative &copy; 2021 – {year}<br />
                            <small>Elite Pro 2006 – 2013</small>
                            </p>
                        </Grid.Column>
                        
                    </Grid.Row>
                </Grid>
            </Container>
     

        
   

    )
    
}
export default Footer