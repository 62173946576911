import React from "react"
import Header from './header'
import Footer from './footer'
import 'semantic-ui-css/semantic.min.css';
import '../styles/base/base.scss';

/*import "uikit/dist/css/uikit.min.css";
import "uikit/dist/js/uikit.min.js";
import "uikit/dist/js/uikit-icons.min.js";*/

//import "uikit/dist/css/uikit-core.min.css";
//import "uikit/dist/js/uikit.min.js";

//import "uikit/dist/js/uikit.min.js";
//import "uikit/dist/js/uikit-icons.min.js";





/*import "uikit/dist/css/uikit-core.min.css";


/*import '../node_modules/uikit/dist/css/uikit.min.cs';
import '../node_modules/uikit/dist/js/uikit.min.js';
import '../node_modules/uikit/dist/js/uikit-icons.min.js';*/

const Layout = (props) => {
    return (
    <div>
        <Header nav={props.page}/>
            {props.children}
        <Footer />
    </div>
    )
}

export default Layout